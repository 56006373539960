<template>
	<div id="container" class="main-contents">
		<div id="contents" class="mem_container">
			<div class="mem_contents_header">						
				contents header
			</div>

			<div>
				<!-- <input type="button" @click="getCommonCode" value="공통코드 조회"/> -->
				<br/>
				회원구분코드(Select Component)
				<br/>
				<SelectComp type="select" v-model="memDivCd" cdId="PRO101" isAll="Y"/>
				<input type="text" v-model="memDivCd"/> 
				<br/><br/>
				<hr/>

				<br/>
				<br/>
				직원구분코드(Radio Component)
				<SelectComp type="radio" v-model="empDivCd" cdId="PRO102"/>
				<input type="text" v-model="empDivCd"/> 
				<br/><br/>
				<hr/>

				<br/>
				<br/>
				기술등급코드(Checkbox Component)
				<SelectComp type="checkbox" v-model="techGradeCd" cdId="PRO106"/>
				TEST : <span v-for="(val, index) in techGradeCd" :key="index">
					{{index}}-{{val}},
				</span>
				<br/><br/>
				<hr/>

			</div>
			
			<br/><br/>
			<div class="mem_contents_body">		

				<div class="Board">
					<table class="Board_type1">
						<colgroup>
							<col width="50%">
						</colgroup>
						<thead>
							<tr>
								<th>제목</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(notice) in noticeList" :key="notice.noticeSeq">
								<td class="head over" @click="clickSj(notice.noticeSeq)">
									<span>{{ notice.noticeTitle }}</span>                  
								</td>
							</tr>									
						</tbody>
					</table>
				</div>

			</div><!--//contents_body-->
		</div><!--//contents-->

	</div><!--//container-->
     
</template>

<script>

// import pagingComp from '@/components/PagingComp.vue';

export default {	

	data () {
		return {      
			noticeList : [],
			commonCode : {},

			memDivCd : '21',
			empDivCd : '03',
			techGradeCd : ['01','02'],

		}
	},

	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');

		this.$store.dispatch("commonCode").then((res) => {
			this.commonCode = res;
		});

		//this.getBbsList();

	},
	beforeUpdate() {
	// console.log('beforeUpdate');
	},
	updated() {
	// console.log('BBS001M01 update');
	},
	beforeDestroy() {
	console.log('beforeDestroy');
	},
	destroyed() {
	console.log('destroyed');
	},    

  methods : {


	search() {
		this.pageIndex = 1;
		this.getBbsList();
	},

   	getBbsList() {
     
		this.$.httpPost('/api/main/notice/getNoticeList', this.input)
			.then(res => {
				console.log(res.data);

				this.bbsList = res.data.list;
			})
			.catch(err => {
				console.log('============================='); 
				console.log(err);
				console.log(err.response);
				console.log('============================='); 

				alert(err.response.data.error_description);
			});
    }
  }
}
</script>